export const state = {
  title: null,
  show: null,
  listFinancialAccounts: [],
  listOfTypeCharge: [],
  listOfReleaseForm: [],
  listOfTypeOfPurpose: [],
  listOfTypesOfQualification: [],
  listOfCompanies: [],
  listOfTitlesFrom: [],
  error: null,
  onSave: null,
};

export const getters = {
  title: (state) => state.title,
  show: (state) => state.show,
  listFinancialAccounts: (state) => state.listFinancialAccounts,
  listOfTypeCharge: (state) => state.listOfTypeCharge,
  listOfReleaseForm: (state) => state.listOfReleaseForm,
  listOfTypeOfPurpose: (state) => state.listOfTypeOfPurpose,
  listOfTypesOfQualification: (state) => state.listOfTypesOfQualification,
  listOfCompanies: (state) => state.listOfCompanies,
  listOfTitlesFrom: (state) => state.listOfTitlesFrom,
  error: (state) => state.error,
  onSave: (state) => state.onSave,
};

export const mutations = {
  setTitle(state, title) {
    state.title = title;
  },
  setShow(state, bool) {
    state.show = bool;
  },
  setListFinancialAccounts(state, listFinancialAccounts) {
    state.listFinancialAccounts = listFinancialAccounts;
  },
  setListOfTypeCharge(state, listOfTypeCharge) {
    state.listOfTypeCharge = listOfTypeCharge;
  },
  setListOfTypesOfQualification(state, typeOfQualification) {
    state.listOfTypesOfQualification = typeOfQualification;
  },
  setListOfCompanies(state, companies) {
    state.listOfCompanies = companies;
  },
  setListOfTitlesFrom(state, titlesFrom) {
    state.listOfTitlesFrom = titlesFrom;
  },
  setListOfReleaseForm(state, listOfReleaseForm) {
    state.listOfReleaseForm = listOfReleaseForm;
  },
  setListOfTypeOfPurpose(state, listOfTypeOfPurpose) {
    state.listOfTypeOfPurpose = listOfTypeOfPurpose
  },
  setError(state, error) {
    state.error = error;
  },
  setOnSave(state, onSave) {
    state.onSave = onSave;
  },
};
